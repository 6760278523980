
@mixin property-drawer-base {
  position: fixed;
  width: 55%;
  height: 100vh;
  background-color: white;
  z-index: 999;
  box-shadow: 0px 0px 10px 2px #888888;
  display: flex;
  flex-direction: column;
}
@mixin textContent{
  font-family: Quicksand;
  font-style: normal;
}
.property-drawer {
  @include property-drawer-base();
  left: -55%;
  transition: left 0.2s linear 0s;
}

.property-drawer-show {
  @include property-drawer-base();
  left: 0;
  transition: left 0.2s linear 0s;
}
.property-drawer-back{
  @include textContent();
  font-weight: bold;
  font-size: 1.1vw;
  line-height: 17px;
  margin:  1.5vw;
  cursor: pointer;
}

.property-header{
  padding: 1.5vw;
  padding-bottom: 3vw !important;
}
.property-header-space-between{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.property-header-text{
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8vw;
  line-height: 2vw;
}
.property-header-subtitle{
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1vw;
  line-height: 22px;
  color: #B2B5C4;
}

.property-header-button{
  background: white;
  color: black;
  border:  1px solid rgba(217, 217, 217, 1);
  min-width: 23%;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 1vw;
  line-height: 24px;
}
.property-header-button:hover{
  background: rgba(79, 123, 234, 1);
  color: white;
  border: none;
}
.property-header-button:disabled{
  background: #F5F5F5;
  color: #BFBFBF;
  border: none;
}
.property-header-navigation{
  margin-top: 1.5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
}
.property-header-navigation-res{
  margin-top: 1.5vw;
  display: flex;
  padding: 0 !important;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
}
@mixin property-header-button {
  background: white;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 90%;
  padding: 5px;
  line-height: 24px;
  height: 35px;
}
.property-header-button-inactive{
  @include property-header-button();
  color: #BFBFBF;
  border: none;
  border-radius: 5px;
}

.property-header-button-active{
  @include property-header-button();
  color: #4F7BEA;
  border: 1px solid #4F7BEA;
  border-right: none;
  border-left: none;
  border-radius: 5px;
  display: flex;
}
.property-header-button-active-left{
  @include property-header-button();
  color: #4F7BEA;
  border: 1px solid #4F7BEA;
  border-right: none;
  border-radius: 5px;
  display: flex;
}
.property-header-button-active-right{
  @include property-header-button();
  color: #4F7BEA;
  border: 1px solid #4F7BEA;
  border-left: none;
  border-radius: 5px;
  display: flex;
}
@mixin after-button{
  content: "";
  width: 24.7px;
  height: 24.7px;
  display: inline-block;
  position: relative;
  left: 14px;
  top: -1px;
  border: 1px solid #4F7BEA;
  border-left: none;
  border-bottom: none;
  border-radius: 0px;
  -webkit-transform: rotate(45deg) ;
  -moz-transform: rotate(45deg) ;
  transform: rotate(45deg) ;
}
.property-header-button-active:after{
  @include after-button();
}
.property-header-button-active-left:after{
  @include after-button();
}
@mixin before-button{
  content: "";
  width: 24.7px;
  height: 24.7px;
  display: inline-block;
  position: relative;
  left: -14px;
  top: -1px;
  border: 1px solid #4F7BEA;
  border-right: none;
  border-top: none;
  border-radius: 0px;
  -webkit-transform: rotate(45deg) ;
  -moz-transform: rotate(45deg) ;
  transform: rotate(45deg) ;
}
.property-header-button-active:before{
  @include before-button();
}
.property-header-button-active-right:before{
  @include before-button();
}
.gradient-divider{
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, rgba(185, 189, 197, 0), rgba(185, 189, 197, 1) 50%, rgba(185, 189, 197, 0) );
}
.property-body{
  flex: 1 1 auto;
  background: #FAFBFD;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
/* width */
div.property-body::-webkit-scrollbar {
  width: 10px;
}

/* Track */
div.property-body::-webkit-scrollbar-track {
  background: #FAFBFD;
}

/* Handle */
div.property-body::-webkit-scrollbar-thumb {
  background: #D9D9D9;
}

/* Handle on hover */
div.property-body::-webkit-scrollbar-thumb:hover {
  background:#D9D9D9;
}

.title-vertical{
  writing-mode: vertical-lr;
  text-orientation: mixed;
  transform: rotateZ(180deg);
  height: 100%;
  text-align: center;
  border-left: 1px solid;
  padding-right: 2vw;
  padding-left: 2vw;
  margin-top: 1.5vw;
  border-image-slice: 1;
  border-image-source: linear-gradient(to top, rgba(185, 189, 197, 0), rgba(185, 189, 197, 1) 50%, rgba(185, 189, 197, 0) );
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 1vw;
  line-height: 1vw;
  color: #4F7BEA;
}
.detail-container{
  flex-direction: column;
  display: flex;
  flex: 1 1 auto;
  justify-content: center;

  .detail-row{
    flex-direction: row;
    display: flex;
    margin-bottom: -1.2vw;
    .detail-col{
      width: 50%;
      margin: 1vw;
      font-family: Quicksand;
      font-style: normal;
      font-weight: bold;
      font-size: 0.9vw;
      line-height: 1vw;
      color: #BFBFBF;
      div{
        font-family: Quicksand;
        font-style: normal;
        font-weight: 500;
        font-size: 1.5vw;
        line-height: 1.6vw;
        margin-top: 1vw;
        color: black;
      }
    }
  }
}
.selected-sites{
  flex-direction: row;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  overflow-x: hidden;
  scroll-behavior: smooth;
  .selected-sites-card{
    width: 30%;
    min-width: 30%;
    height: 25vh;
    background: white;
    margin-left: 2vw;
    display: flex;
    flex-direction: column;
    .search-div{
      margin: 0.8vw;
      background: #4F7BEA;
      height: 60%;
      width: calc(100% - 1.6vw);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .search-title{
      margin: 0.8vw;
      font-family: Quicksand;
      font-style: normal;
      font-weight: 500;
      font-size: 1vw;
      line-height: 1.2vw;
    }

    .search-input{
      margin: 0.8vw;
      margin-top: 0 !important;
      display: flex;
      flex-direction: row;
      border: 1px solid #F0F0F0;

      input{
        width: calc(100% - 5px);
        border: none;
        flex: 1 1 auto;
        margin: 0 !important;
        font-size: 1vw;
      }
      input:focus{
        outline: none;
      }
      img{
        margin: 0.5vw;
        cursor: pointer;

      }
    }
  }
}
.configuration-card{
width: 30%;
  min-width: 30%;
  height: 50vh;
  background: white;
  margin-left: 2vw;
  display: flex;
  flex-direction: column;
}
.configuration-img{
  margin: 0.8vw;
  background: #4F7BEA;
  height: 30%;
  width: calc(100% - 1.6vw);
  display: flex;
  align-items: center;
  justify-content: center;
}
.configuration-header{
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 1vw;
  line-height: 1.2vw;
  letter-spacing: 0.1vw;
  text-transform: uppercase;
  color: #BFBFBF;
}
.configuration-text{
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2vw;
  line-height: 1.2vw;
margin-top: 0.5vw;
  margin-bottom: 0.5vw;
  color: #262626;
}
.stick-dots{
  position: sticky;
  left: 4vw;
  top: 30vw;
  display: flex;
  flex-direction: row
}
.blue-bar{
  width: 1.5vw;
  height: 0.8vw;
  background: #4F7BEA;
  border-radius: 0.4vw;
  margin: 0.2vw;
  transition: width 0.2s linear 0s;;
}
.grey-dot{
  width: 0.8vw;
  height: 0.8vw;
  background: #D9D9D9;
  border-radius: 50%;
  margin: 0.2vw;
  transition: width 0.2s linear 0s;;
}
.configuration-button{
  margin: 0.8vw;
  border:none;
  background: #4F7BEA;
  color: white;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  padding: 0.5vw;
  font-size: 70%;
}
.configuration-container{
  overflow-x: hidden;
  flex-direction: row;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  scroll-behavior: smooth;
}
.rulerContainer{
  width: 6vw;
  margin-right: 2vw;
  margin-left: 2vw;
  display: flex;
 flex-direction: row;
}
.rulerValue{
  font-size: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 1vw;
}

.rulerDivider{
  border-right: none !important;
  border: 0.3vw solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to top, rgba(64, 122, 242, 0), rgba(64, 122, 242, 1) 50%, rgba(64, 122, 242, 0) );
}

.valuationCard{
  background: white;
  width: 15vw;


  margin-right: 3vw;
  display: flex;
  flex-direction: column;
  //border: 1px solid black;

}
.valuationCard:before{
  content: " ";
  width: 10px;
  height: 10px;
  background: white;
  transform: rotate(45deg);
  display: inline-block;
  position: relative;
  top:calc(50% - 5px);
  left: -6px;
  -webkit-transform: rotate(45deg) ;
  -moz-transform: rotate(45deg) ;
  //border: 1px solid black;
  //border-top: none;
  //border-right: none;
}
.valuation-font{
  font-family: Quicksand;
  font-style: normal;
}
.valuationSideCard{
  background: white;
  width: 15vw;
  margin-right: 3vw;
  height: min-content;
  align-self: center;
}
.progress-div{
  position: relative;
}
.moving-progress{
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.progress-div{
  padding-left: 5vw;
  margin-left: 5vw;
  border-left: 0.4vw dotted #BFBFBF;
}
.progress-div:before{
  content: " ";
  position: absolute;
  display: inline-block;
  width: 1vw;
  height: 1vw;
  left: -0.73vw;
  background: white;
  top: -0.4vw;
  border: 0.3vw solid #4F7BEA;
  border-radius: 100%;
}


.valuation-card-title{
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 0.8vw
}
.valuation-card-divider{
  border: 1px solid #F0F0F0;
  margin-top: 0.7vw;
  margin-bottom: 0.7vw;
}
.valuation-body{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.valuation-tag{
  background: #F1F7FE;
  color: #4F7BEA;
  font-size: 0.8vw;
  padding-left: 0.4vw;
  padding-right: 0.4vw;
  margin-top: 1vw;
}
.median-sale-price{
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 0.8vw;
  margin-top: 0.6vw;
}
.rise-model{
  position: fixed; /* Stay in place */
  z-index: 30; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  display: block;
}

@mixin model-base{
  background: #FFFFFF;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  animation-duration: 0.5s
}
.list-for-sale{
  @include model-base();
  width: 30vw;
  margin: 30vh auto;
  animation-name: animatetop;

}
@-webkit-keyframes animatetop {
  from {  margin: -30vh auto; opacity:0}
  to {  margin: 30vh auto; opacity:1}
}

@keyframes animatetop {
  from {  margin: -30vh auto; opacity:0}
  to {  margin: 30vh auto; opacity:1}
}
.model-header{
 @include textContent();
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 1.4vw;
  margin-bottom: 1.4vw;
  font-weight: bold;
  font-size: 1.1vw;
  border-bottom: 2px solid rgba(240, 240, 240, 1);
}
.model-body{
  @include textContent();
  text-align: left;
  margin-left: 1.4vw;
  margin-right: 1.4vw;
  font-size: 1vw;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

}
.model-footer{
  @include textContent();
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.4vw;
  margin-top: 1.4vw;
  font-size: 1.1vw;
  border-top: 2px solid rgba(240, 240, 240, 1);
}
.button-decline{
  border: 1px solid  #D9D9D9;
  background: white;
  border-radius: 2px;
  padding: 0.3vw 1vw;
}
.button-accept{
  border: none;
  color: white;
  background: #4F7BEA;
  margin-left: 0.7vw;
  border-radius: 2px;
  padding: 0.3vw 1vw;
}
.rise-model-divider{
  border: 1px solid rgba(240, 240, 240, 1);
  width: 100%;
  margin-top: 1.4vw ;
  margin-bottom: 1.4vw ;
}
.footer-hint-text{
  color: rgba(191, 191, 191, 1);
  font-size: 1vw;
}
.partners{
  @include model-base();
  width: 50vw;
  height: 60vh;
  margin: 20vh auto;
  display: flex;
  flex-direction: column;
  animation-name: partner-top;
}
.ownership{
  @include model-base();
  width: 50vw;
  height: 50vh;
  margin: 20vh auto;
  display: flex;
  flex-direction: column;
  animation-name: partner-top;
}
.summary{
  @include model-base();
  width: 50vw;
  height:80vh;
  margin: 10vh auto;
  display: flex;
  flex-direction: column;
  animation-name: ownership-top;
}
@-webkit-keyframes ownership-top {
  from {  margin: -30vh auto; opacity:0}
  to {  margin: 10vh auto; opacity:1}
}

@keyframes ownership-top {
  from {  margin: -30vh auto; opacity:0}
  to {  margin: 10vh auto; opacity:1}
}
@-webkit-keyframes partner-top {
  from {  margin: -30vh auto; opacity:0}
  to {  margin: 20vh auto; opacity:1}
}

@keyframes partner-top {
  from {  margin: -30vh auto; opacity:0}
  to {  margin: 20vh auto; opacity:1}
}
.partners-input-div{
  display: flex;
  flex-direction: column;
  div{
    margin-top: 1.4vw;
    border: 1px solid #D9D9D9;
    position: relative;
    input{
      border: none;
      outline: none;
      padding: 0.5vw;
    }
    img{
      position: absolute;
      left: calc(100% - 1.5vw);
      top:0.5vw;
      width: 1vw;
    }
  }
}
.partners-body{
  margin-top: 1.4vw;
  flex: 1 1 auto;
  overflow-y: auto;
 height: 0;
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;
}

.partners-card{
  width: 30%;
  margin-right: 2%;
  margin-bottom: 0.7vw;
  display: flex;
  flex-direction: column;
  padding: 1vw;
  justify-content: space-around;
  height: 6vw;
  background: #FCFCFE;
  .title{
    font-size: 1vw;
    color: #4F7BEA;
    font-weight: bold;
  }
  .name{
    font-size: 0.7vw;
    img{
      border-radius: 100%;
      margin-right: 0.6vw
    }
  }
  .date{
    font-size: 0.7vw;
    color: #BFBFBF;
  }
}
.ownership-input-div{
  background: #F8FAFF;;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  padding: 1vw;
}
.ownership-input{
  @include textContent();
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-right: 5%;
  color: rgba(178, 181, 196, 1);
  font-size: 1vw;
  input{
  margin-top: 0.5vw;
    border: 1px solid #D9D9D9;
  }
}
.summary-row-space-between{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.summary-row-justify-left{
  display: flex;
  flex-direction: row;
  justify-content: left;
}
.summary-sub-div{
  padding: 1vh;
  background: #F8FAFF;
  width: 100%;
  height: 18vh;
  margin-top: 1vw;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}
.project-progress-title{
  font-size: 2vh;
  font-weight: bolder;
}
#project-progress-money{
  color: #BFBFBF;
  font-size: 1.8vh;
}
@mixin inner-circle{
  content: " ";
  height: 1vh;
  width: 0.5vh;
  background-color:#FFF;
  display:inline-block;
  border-radius:0 1vh 1vh 0;
  position: absolute;
}
@mixin progress-bar{
  height: 1vh;
  border-radius: 1vh;
  position: relative;
}
#progress-1{
  @include progress-bar();
  width: 20%;
  background: #385CC5;
}
#progress-2{
  @include progress-bar();
  width: 20%;
  background: #78A1F8;
}
#progress-2:before{
  @include inner-circle;
}
#progress-3{
  @include progress-bar();
  width: 20%;
  background: #C2D8FC;
}
#progress-3:before{
  @include inner-circle;
}
#progress-4{
  @include progress-bar();
  width: 40%;
  background: white;
}
#progress-4:before{
  @include inner-circle;
}
#step-1{
  @include progress-bar();
  width: 1vh;
  background: #385CC5;
  margin-right: 1vh;
}
#step-2{
  @include progress-bar();
  width: 1vh;
  background: #78A1F8;
  margin-right: 1vh;
  margin-left: 1vh;
}
#step-3{
  @include progress-bar();
  width: 1vh;
  background: #C2D8FC;
  margin-right: 1vh;
  margin-left: 1vh;
}
.row-align-center{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.category-title{
  width: 20%;
  height: 16vh;
  border-right: 1px solid #D9D9D9;
  margin-right: 4vh;
  a{
    text-decoration: none;
  }
}
.column-div{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.column-wrap{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  div{
    width: 40%;
  }
}
.summary-subtitle{
  color: #BFBFBF;
  font-size: 1.8vh;
}
.summary-information{
  margin-bottom: 0.5vh;
  font-size: 1.8vh;
}
.total{
  width: 100%;
  display: flex;
  flex-direction: row;
}
#total-money{
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;
  border-top: 1px solid #4F7BEA;
  .summary-number{
    font-size: 1.8vh;
    color: #4F7BEA;
  }
}

