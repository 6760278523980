.map-container {
  display: flex;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  &__map {
    height: 100vh;
    width: 100%;
  }
}

@mixin drawer_base {
  position: fixed;
  width: 20%;
  height: 100vh;
  background-color: white;
  z-index: 10;
}
.drawer {
  @include drawer_base();
  left: -20%;
  transition: left 0.2s linear 0s;
}

.drawer_show {
  @include drawer_base();
  left: 0;
  transition: left 0.2s linear 0s;
}

@mixin drawer_below_wide_base{
  position: fixed;
  width: 35%;
  height: 100vh;
  background-color: white;
  z-index: 8;
  transition: left 0.2s linear 0s;
}
.drawer_below_close{
  @include drawer_below_wide_base();
  left: -35%;
}
.drawer_below_half_hide{
  @include drawer_below_wide_base();
  left: -15%;
}
.drawer_below_half_open{
  @include drawer_below_wide_base();
  left: 5%;
}
.drawer_below_open{
  @include drawer_below_wide_base();
  left: 20%;
}

@mixin drawer_button_base {
  position: fixed;
  top: 50%;
  height: 50%;
  transform: translate(0, -50%);
  transition: left 0.2s linear 0s;
}
.drawer_button {
  @include drawer_button_base();
  left: 0;
}
.drawer_button_open {
  @include drawer_button_base();
  left: 20%;
}
.drawer_button_more_open {
  @include drawer_button_base();
  left: 40%;
}
.drawer_button_fully_open {
  @include drawer_button_base();
  left: 55%;
}

.menu_title {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 2vw;
  line-height: 2vw;
  margin-left: 1vw;
  margin-top: 1vw;
  color: #407af2;
}
.divider {
  border-bottom: 1px solid #f2f6ff;
  width: 100%;
  margin-bottom: 1vw;
  margin-top: 1vw;
}
.list_title {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5vw;

  line-height: 1.5vw;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-left: 1vw;
  color: #7c88a0;
}
.list_item {
  font-family: Quicksand;
  font-style: normal;
  font-size: 1.3vw;
  font-weight: 500;
  cursor: pointer;
  line-height: 1.3vw;
  margin-left: 1vw;
  color: #2d2d2d;
  margin-bottom: 1vw;
  img {
    margin-right: 1vw;
  }
}
.list_card {
  background-image: url(../icons/mapMenuButtons/menuBackground.svg);
  background-size: contain;
  width: 16vw;
  height: calc(100vw * 0.099);
  position: absolute;
  bottom: 1.5vw;
  left: 1vw;
  // margin-top: auto;
  // margin-left: 1vw;
  display: flex;
  flex-direction: column;
  .cardtitle {
    margin: 10px;
    font-family: Quicksand;
    font-style: normal;
    font-size: 1.2vw;
    font-weight: 500;
    color: #ffffff;
    text-decoration: none;
    margin-bottom: 0px !important;
  }
  .cardtext {
    margin-left: 10px;
    font-family: Quicksand;
    font-style: normal;
    font-size: 0.8vw;
    font-weight: normal;
    color: #d2def9;
    text-decoration: none;
  }
  .cardfooter {
    font-family: Quicksand;
    font-style: normal;
    font-size: 0.8vw;
    font-weight: normal;
    color: #d2def9;
    text-decoration: none;
    margin-right: 2vw;
    margin-top: auto;
    margin-left: auto;
  }
  .progressbar {
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal;
    color: #d2def9;
    margin: 1vw;
    margin-top: auto !important;
    width: 80%;
  }
}
@mixin mapSearching {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 60px;
  border-radius: 60px;
  padding-left: 30px;
  border-color: rgba(7, 21, 58, 0.25);
  transform: translate(-50%, -50%);
  outline: none;
  z-index: 6;
}
.mapsearching_start {
  @include mapSearching();
  width: 60px;
  border: none;
  transition: width 0.2s linear 0s;
}
.mapsearching_end {
  @include mapSearching();
  width: 500px;
  transition: width 0.2s linear 0s;
}
@mixin mapSearchIcon {
  position: fixed;
  top: 50%;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  z-index: 7;
  transform: translate(-50%, -50%);
}
.mapSearchIcon_start {
  @include mapSearchIcon();
  left: 50%;
  transition: left 0.2s linear 0s;
}
.mapSearchIcon_end {
  @include mapSearchIcon();
  left: calc(50% + 220px);
  transition: left 0.2s linear 0s;
}
.pac-container {
  //width: 440px !important;
  //margin-left: 30px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 1px 1px 8px rgba(124, 136, 160, 1);
}
.pac-item {
  border: none;
  outline: none;
}
.search-box-1{
  width: 440px !important;
  margin-left: 20px !important;
}
.search-box-2{
  height: 4.5vw !important;
  border-radius: 0 !important;
  padding-top: 0.3vw !important;
  padding-left: -1vw !important;
  .pac-item{
    font-size: 0.5vw !important;
    line-height: 1vw !important;
    margin-bottom: 0.5vw !important;
    .pac-icon{
      height: 0vw !important;
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      width: 0 !important;
    }
    .pac-item-query{
      font-size: 0.8vw !important;
    }
  }
}
