.filterDiv{
  height: 100vh;
  width: 57.1%;
  margin-left: auto;
  display: flex;
  flex-direction: column;
}

@mixin filter-and-list-font{
  font-family: Quicksand;
  font-style: normal;
}
@mixin flex-column{
  display: flex;
  flex-direction: column;
}
.filter-list{
  padding: 1vw;
  margin: 1vw;
  overflow-y: auto;
  @include filter-and-list-font();
  @include flex-column();
  .column{
    @include flex-column();
  }
}

.filter-container{

  margin-top: 1vw;
  margin-bottom: 1vw;
  background: #FAFAFA;
  padding:0.5px
}
.filter-item{
  font-size: 1vw;
  @include filter-and-list-font();
  align-items: center;
    padding-left: 1vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  margin-top: 0.5vw;
  margin-bottom: 0.5vw;

}
.half-divider{
  border: 1px solid #F0F0F0;
  margin-left: 1vw;
  margin-right:1vw
}

.bottom-drawer{
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.filter-and-list-header{
 @include filter-and-list-font();
  img{
    margin-right: 1vw;
  }
  font-weight: 500;
  font-size: 2vw;
  line-height: 2vw;
  padding: 1vw;
  color: #407af2;
  border-bottom: 1px solid #F0F0F0;
}
.four-even-tag-horizontal{
  display: flex;
  flex-direction: row;
  div{
    @include filter-and-list-font();
    width: 25%;
    display: flex;
    padding-top: 1vw;
    cursor: pointer;
    padding-bottom: 1vw;
    font-size: 1vw;
    border-bottom: 1px solid #F0F0F0;
    justify-content: center;
  }
  .selected{
    color: #4F7BEA;
    border-bottom: 1px solid  #4F7BEA;;
  }
}
.space-between-horizontal{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.5vw;
  border-bottom: 1px solid #F0F0F0;
  font-size: 1.2vw;
  @include filter-and-list-font();
  .description{
    color: #4F7BEA;
  }
  .sort{
    select{
      border: 1px solid #D9D9D9;
      margin-left: 1vw;
      outline: none;
      border-radius: 5px;
      padding: 0.5vw;
      cursor: pointer;
    }
    select:focus{
      border: #4F7BEA;
      background: #4F7BEA;
      outline: none;
      color: white;
    }
  }
}
.list-search{
  border-bottom: 1px solid #F0F0F0;
  padding: 1vw;
  display: flex;
  flex-direction: column;
  @include filter-and-list-font();
  .header{
    display: flex;
    flex-direction: row;
    .items{
      color: #4F7BEA;
      font-size: 1vw;
    }
    .hint-text{
      margin-left: 1vw;
      color: #BFBFBF;
      font-size: 1vw;
    }
  }
  .search-bar{
    display: flex;
    flex-direction: row;
    margin-top: 0.5vw;
    .select{
      background:  #FAFAFA;
      border: 1px solid rgba(217, 217, 217, 1);
      padding: 0.5vw;
      font-size: 1vw;
    }
    .input{
      flex: 1 1 auto;
      border: 1px solid rgba(217, 217, 217, 1);
      padding: 0.5vw;
      font-size: 1vw;
    }
  }
}

div.list-scroll::-webkit-scrollbar{
  width: 10px;
}
div.list-scroll::-webkit-scrollbar-track {
  background: #FAFBFD;
}
div.list-scroll::-webkit-scrollbar-thumb{
  background: #D9D9D9;
}
.list-scroll{
  overflow-y: scroll;
  width: 100%;
}
@mixin width-row{
  width: 100%;
  display: flex;
  flex-direction: row;
}
@mixin flex-center{
  display: flex;
  align-items: center;
}
.property-card{
  @include width-row();
  height: 10vw;
  min-height: 10vw;
  padding: 1vw;
  @include filter-and-list-font();
  .img{
    width: 30%;
    margin-right: 1vw;
  }
  .text{

    flex: 1 1 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    .title{
      font-size: 1.2vw;
      flex: 1;
      @include flex-center();
      @include width-row();
      justify-content: space-between;
    }
    .price-location{
      font-size: 0.9vw;
      flex: 1;
      @include flex-center();
      @include width-row();
      div{
        background: #F1F7FE;
        color:rgba(79, 123, 234, 1);
        padding: 0.2vw;
        margin-right: 1vw;
      }
      color: rgba(120, 161, 248, 1);
    }
    .tag{
      font-size: 0.9vw;
      flex: 1;
      @include flex-center();
      @include width-row();
      color: #BFBFBF;
      div{margin-right: 1vw}
    }
  }
}
