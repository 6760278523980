@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@mixin flexrow {
  display: flex;
  flex-direction: row;
}
@mixin flexcolumn {
  display: flex;
  flex-direction: column;
}
@mixin noButtonShading {
  outline: none !important;
  box-shadow: none !important;
}
@mixin buttonBase {
  border-radius: 8px !important;
  border: none;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: left !important;
}

$bluebg: #f2f6ff;
$bluefont: #407af2;
$bluebutton: #eef2fc;
$greybutton: #7c88a0;

.containerCreateAccount {
  @include flexcolumn();
  height: 100vh;
  width: 100vw;
  background: white;
}
.createAccountHeader {
  @include flexrow();
  height: 80px;
  width: 100vw;
  justify-content: space-between;
  align-items: center;
  .headerText {
    font-family: Quicksand;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #7c88a0;
  }
}
.createAccountBody {
  @include flexrow();
  height: calc(100vh - 80px);
  width: 100vw;
  .createAccountMenu {
    width: 25vw;
    display: flex;
    justify-content: center;
    padding-top: 130px;
    .menubuttonNormal {
      @include buttonBase();
      background-color: transparent;
      color: $greybutton;

      img {
        //https://codepen.io/sosuke/pen/Pjoqqp?__cf_chl_jschl_tk__=ecc0b72797ae71bc009d6322e3e470773936b386-1604211766-0-ASpz720gXnc6Ej0vzlgY9-KLmlPkldgcOx1wAmGTUCjLZLOxkArNxpRzZ9m8woL-NGmP9LBGVPws8UxMJZrR7O1qFH6QkKtrGVPw6StRnXiK1XTQR_nY905r0XobAG2nOmyC6Zq8mdyPDp1MyHD7JLodJUXCRViXhtmLmRVE_-JGarVJRlxs6k3DzAOQQEJewfp00DjhlD0mxr8ZKpk2yq6IPTZZQ52XYxh26FC5MxLHhs7LuAwhtolmDZyp4_IuwRg8I5m-2--MmvGE8CCqjRWrkE85zgkMXPlOqcZtppRpZhn6Uz9DZAuKheHwVBb0ySIhFYG92bvQOgiKX0TTswB1SHgOLIeqktuyUaAgxI_h
        filter: invert(55%) sepia(22%) saturate(336%) hue-rotate(182deg)
          brightness(93%) contrast(92%);
      }
    }
    .menubuttonSelected {
      @include buttonBase();
      background: $bluebutton;
      color: $bluefont;

      img {
        filter: invert(38%) sepia(42%) saturate(3858%) hue-rotate(210deg)
          brightness(102%) contrast(90%);
      }
    }
    .menubuttonSelected:focus {
      @include noButtonShading();
    }
    .menubuttonNormal:focus {
      @include noButtonShading();
    }
  }
  .createAccountMain {
    width: 75vw;
    .createAccountMainHeader {
      position: absolute;
      left: 50%;
      transform: translateX(-55%);
      padding-top: 20px;
      .stepHeader {
        font-family: Quicksand;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #7c88a0;
        margin-bottom: 0;
      }
      .titleHeader {
        font-family: Quicksand;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        color: #000000;
      }
    }
    .createAccountMainContent {
      position: absolute;
      left: 50%;
      transform: translateX(-55%);
      padding-top: 100px;
      width: 50%;
      align-items: flex-start;
      width: 40vw;
      .createAccountTitle {
        font-family: Quicksand;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
      }
      .createAccountSubTitle {
        font-family: Quicksand;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: #7c88a0;
      }
      .createAccountCard {
        border-radius: 10px !important;
        border-color: #d2def9;
      }
      .createAccountCardHeader {
        display: flex;
        justify-content: space-between;
        background-color: $bluebg;
        padding-top: 20px;
        padding-bottom: 20px;
        border-top-right-radius: 10px !important;
        border-top-left-radius: 10px !important;
        border-color: #d2def9;
        color: $bluefont;
        font-family: Quicksand;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
      }
      .createAccountCardHeaderSquare {
        border-top: 1px solid;
        border-radius: 0;
        margin-left: -1rem;
        margin-right: -1rem;
        display: flex;
        justify-content: space-between;
        background-color: $bluebg;
        padding-top: 20px;
        padding-bottom: 20px;
        border-color: #d2def9;
        color: $bluefont;
        font-family: Quicksand;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
      }
      .createAccountCardBodyColumn {
        @include flexcolumn();
      }
      .createAccountLabel {
        font-family: Quicksand;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $greybutton;
        margin-top: 20px;
      }
      .addCoDevButton {
        background-color: #f2f6ff;
        border: none;
        color: #7c88a0;
        font-family: Quicksand;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        margin-top: 15px;
        padding: 10px;
      }
      .createAccountCardFooter {
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: white;
        border-color: #d2def9;
        border-bottom-right-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
        .formButton {
          font-family: Quicksand;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
        }
      }

      @mixin form-input-base {
        border: solid;
        border-radius: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 16px;
        padding-right: 16px;
        font-family: "Quicksand";
        margin-top: 8px;
        width: 100%;
      }
      .formIntro {
        font-family: Quicksand;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: $greybutton;
      }
      .form-input {
        @include form-input-base();
        border-color: #d2def9;
        border-width: 1px;
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type="number"] {
          -moz-appearance: textfield;
        }
      }
      .co-dev-name {
        width: 30% !important;
      }
      .co-dev-email {
        width: 35% !important;
        margin-left: 1%;
      }
      .co-dev-phone {
        width: 30% !important;
        margin-left: 1%;
      }
      @media screen and (max-width: 1000px) {
        .co-dev-name {
          width: 100%;
        }
        .co-dev-email {
          width: 100%;
        }
        .co-dev-phone {
          width: 100%;
        }
      }
      .form-input:focus {
        @include form-input-base();
        border-width: 2px;
        border-color: #407af2 !important;
      }
      .form-input:hover {
        @include form-input-base();
        border-width: 2px;
        border-color: #407af2 !important;
      }
    }
  }
}
.validatingHintCreate {
  align-self: flex-start;
  margin-left: 35px;
  color: red;
  font-size: 13px;
  font-family: Quicksand;
  text-decoration: none;
}
.validatingHintIncome {
  align-self: flex-start;
  margin-left: 5px;
  color: red;
  font-size: 13px;
  font-family: Quicksand;
  text-decoration: none;
}
.progress-day{
  z-index: 20;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
