@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@mixin flexrow {
  display: flex;
  flex-direction: row;
}
@mixin flexcolumn {
  display: flex;
  flex-direction: column;
}

$bluebg: #f2f6ff;

.containerLogin {
  @include center();
  @include flexcolumn();
  height: 100vh;
  width: 100vw;
  background: $bluebg;
}
.logInBox {
  @include center();
  @include flexcolumn();
  background: white;
  height: 449px;
  width: 50%;

  .headerText {
    font-family: Quicksand;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
  }
  .subtitle {
    font-family: Quicksand;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 18px;
    line-height: 22px;
    color: #2d2d2d;
    max-width: 70%;
  }
  .handingimg {
    @include flexrow();
    width: inherit;
    justify-content: center;
  }
  .logInForm {
    @include flexcolumn();
    align-self: center;
    margin: 30px;
    width: 70%;
    @mixin form-element {
      margin: 20px;
    }
    @mixin form-input-base {
      border: solid;
      border-radius: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 16px;
      padding-right: 16px;
      font-family: "Quicksand";
    }
    .form-input {
      @include form-element();
      @include form-input-base();
      border-color: #f2f6ff;
      border-width: 1px;
    }
    .form-input:focus {
      @include form-element();
      @include form-input-base();
      border-width: 2px;
      border-color: #407af2 !important;
    }
    .form-input:hover {
      @include form-element();
      @include form-input-base();
      border-width: 2px;
      border-color: #407af2 !important;
    }
    .form-button {
      @include form-element();
      background-color: #407af2;
      color: white;
      padding: 10px;
      font-family: Quicksand;
      font-weight: bold;
      text-transform: unset !important;
      border-radius: 10px;
    }
  }
}
.hinttext {
  font-family: Quicksand;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #7c88a0;
  margin-top: 20px;
}
.validatingHint {
  margin-top: -18px;
  align-self: flex-start;
  margin-left: 35px;
  color: red;
  font-size: 13px;
  font-family: Quicksand;
  text-decoration: none;
}
